var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.DASHBOARD")))])])])]}}])}),(_vm.getAccess.includes('recent_transaction--READ'))?_c('router-link',{attrs:{"to":"/transaction"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-crisp-icons"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.RECENT_TRANSACTION")))])])])]}}],null,false,2715892405)}):_vm._e(),(_vm.getAccess.includes('deposit--READ'))?_c('router-link',{attrs:{"to":"/deposit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-sheet"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.DEPOSIT")))])])])]}}],null,false,1715151732)}):_vm._e(),(_vm.getAccess.includes('withdraw--READ'))?_c('router-link',{attrs:{"to":"/withdrawal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-coins"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.WITHDRAWAL")))])])])]}}],null,false,2043909518)}):_vm._e(),(_vm.getAccess.includes('tier--READ') && _vm.downlineTier)?_c('router-link',{attrs:{"to":"/tier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-avatar"}),(_vm.downlineTier.tier == 2)?_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.AGENT_LIST")))]):(_vm.downlineTier.tier == 3)?_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.CLIENT_LIST")))]):_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.MEMBER_LIST")))])])])]}}],null,false,3869598966)}):_vm._e(),(_vm.getAccess.includes('operator--READ') && _vm.downlineTier)?_c('router-link',{attrs:{"to":"/operator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.OPERATOR_LIST")))])])])]}}],null,false,993709339)}):_vm._e(),(_vm.getAccess.includes('admin_access--READ'))?_c('router-link',{attrs:{"to":"/role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.ROLE")))])])])]}}],null,false,2364821728)}):_vm._e(),(_vm.getAccess.includes('currency--READ'))?_c('router-link',{attrs:{"to":"/currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cd"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.CURRENCY_LIST")))])])])]}}],null,false,3105286614)}):_vm._e(),(_vm.getAccess.includes('setting--READ'))?_c('router-link',{attrs:{"to":"/setting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-gear"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTING")))])])])]}}],null,false,428398316)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }