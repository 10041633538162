<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{ $t("MENU.DASHBOARD") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('recent_transaction--READ')"
      to="/transaction"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-crisp-icons"></i>
          <span class="menu-text">{{ $t("MENU.RECENT_TRANSACTION") }}</span>
        </a>
      </li>
    </router-link>
    
    <router-link
      v-if="getAccess.includes('deposit--READ')"
      to="/deposit"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-sheet"></i>
          <span class="menu-text">{{ $t("MENU.DEPOSIT") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('withdraw--READ')"
      to="/withdrawal"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">{{ $t("MENU.WITHDRAWAL") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('tier--READ') && downlineTier"
      to="/tier"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-avatar"></i>
          <span class="menu-text" v-if="downlineTier.tier == 2">{{
            $t("MENU.AGENT_LIST")
          }}</span>
          <span class="menu-text" v-else-if="downlineTier.tier == 3">{{
            $t("MENU.CLIENT_LIST")
          }}</span>
          <span class="menu-text" v-else>{{ $t("MENU.MEMBER_LIST") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('operator--READ') && downlineTier"
      to="/operator"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user-1"></i>
          <span class="menu-text">{{ $t("MENU.OPERATOR_LIST") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('admin_access--READ')"
      to="/role"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-group"></i>
          <span class="menu-text">{{ $t("MENU.ROLE") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('currency--READ')"
      to="/currency"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cd"></i>
          <span class="menu-text">{{ $t("MENU.CURRENCY_LIST") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="getAccess.includes('setting--READ')"
      to="/setting"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-gear"></i>
          <span class="menu-text">{{ $t("MENU.SETTING") }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["downlineTier", "getAccess"])
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
